<template>
  <div>
    <ejs-dialog
      ref="storeTableDetailPopup"
      header="테이블 속성 관리"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="542"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :position="popupPosition"
      :zIndex="currentzIndex"
      :isModal="$props.isModal"
      :visible="isVisible"
    >
      <div class="window storeTableDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">테이블명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="tableName"
                                        v-model="storeTableInfo.tableName"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">사용여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="useFlagTrue"
                                            v-model="storeTableInfo.useFlag"
                                            :value="true"
                                          />
                                          <i></i>
                                          <div class="label">사용</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="useFlagFalse"
                                            v-model="storeTableInfo.useFlag"
                                            :value="false"
                                          />
                                          <i></i>
                                          <div class="label">미사용</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">테이블 색상</div>
                              <div class="content color">
                                <ul class="row">
                                  <li class="item form-group value">
                                    <!-- TODO : null 값일때 투명색 or 하얀색 처리, 값은 그대로 null 값 -->
                                    <!-- 포커스 : focus -->
                                    <div class="value">
                                      <ejs-colorpicker
                                        ref="colorValue"
                                        id="color"
                                        v-model="storeTableInfo.colorValue"
                                        cssClass="body-data-colorpicker"
                                      ></ejs-colorpicker>
                                    </div>
                                  </li>
                                  <!-- TODO : 색상 변경 시 HEX코드 여섯자리 뒤 ff가 추가됨 -->
                                  <li class="item form-group text">
                                    {{ storeTableInfo.colorValue }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">주방출력여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="kitchenPrtFlagTrue"
                                            v-model="
                                              storeTableInfo.kitchenPrtFlag
                                            "
                                            :value="true"
                                          />
                                          <i></i>
                                          <div class="label">사용</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="kitchenPrtFlagFalse"
                                            v-model="
                                              storeTableInfo.kitchenPrtFlag
                                            "
                                            :value="false"
                                          />
                                          <i></i>
                                          <div class="label">미사용</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-icon-custom="true"
                  v-on:click.native="onSaveButtonClick"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="storeTableDetailPopupClose"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { validateFormRefs } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "storeTableDetailPopup",
  mixins: [commonMixin, confirmDialogMixin],
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  components: {
    InputText,
    ErpButton,
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      animationSettings: { effect: "None" },
      popupPosition: { X: this.$props.position.x, Y: this.$props.position.y },
      currentzIndex: this.$props.zIndex,
      storeTableInfo: {},
      rules: {
        tableName: {
          required: true,
        },
      },
    };
  },
  methods: {
    validateFormRefs,
    setTableBackgroundColor(rgb) {
      if (this.storeTableInfo.colorValue === undefined) {
        Vue.set(this.storeTableInfo, "colorValue", rgb);
      } else {
        this.storeTableInfo.colorValue = rgb;
      }
    },
    loadData() {
      if (
        this.storeTableInfo.colorValue === undefined ||
        this.storeTableInfo.colorValue === null
      ) {
        this.$refs.colorValue.ej2Instances.value = "#ffffff";
        this.$refs.colorValue.ej2Instances.properties.value = "#ffffff";
      }

      if (
        this.storeTableInfo.useFlag === null ||
        this.storeTableInfo.useFlag === undefined
      ) {
        this.storeTableInfo.useFlag = true;
      }

      if (
        this.storeTableInfo.kitchenPrtFlag === null ||
        this.storeTableInfo.kitchenPrtFlag === undefined
      ) {
        this.storeTableInfo.kitchenPrtFlag = true;
      }
    },
    setZIndex(zIndex) {
      this.currentzIndex = zIndex;
    },
    storeTableDetailPopupClose() {
      this.$refs.storeTableDetailPopup.hide();
    },
    dialogClose() {
      this.$emit(
        "popupEvent",
        "popupClosed",
        this.storeTableInfo.tableId,
        this.$props.value
      );
    },
    onDialogClicked() {
      this.$emit(
        "popupEvent",
        "popupClicked",
        this.$props.popupKey,
        this.$props.value
      );
    },
    showPopup(data) {
      this.$refs.storeTableDetailPopup.show();
      this.storeTableInfo = data;
      if (
        this.storeTableInfo.colorValue === undefined ||
        this.storeTableInfo.colorValue === null
      ) {
        this.storeTableInfo.colorValue = "#ffffff";
      }
    },
    onSaveButtonClick() {
      if (!this.validateFormRefs(this.rules)) {
        return;
      }

      this.$emit("putStoreTable", this.storeTableInfo);
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field store">
            <div class="title">매장</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="searchStoreCode"
                  v-model="searchOptions.storeCode"
                  :dataSource="searchOptions.storeCodeOptions"
                  :allowFiltering="false"
                  :fields="searchOptions.fields"
                  cssClass="lookup-condition-dropdown"
                  @change="onSearchOptionsStoreCodeChange"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewButtonClick"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field">
            <div class="title">가로</div>
            <ul class="content">
              <li class="item">
                <input-number
                  v-model="searchOptions.colNo"
                  :prop-max-length="3"
                  style="background-color: #fff"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">세로</div>
            <ul class="content">
              <li class="item">
                <input-number
                  v-model="searchOptions.rowNo"
                  :prop-max-length="3"
                  style="background-color: #fff"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="create">
                    <erp-button
                        button-div="SAVE"
                        :is-icon-custom="true"
                        v-on:click.native="onTableCreateButtonClick"
                    >
                      테이블 생성
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">매장 테이블 목록</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="reset">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      v-on:click.native="onInitButtonClick"
                  >
                    초기화
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="table-body"
            :class="[tableId.start ? 'section-body draggable' : 'section-body']"
          >
            <div style="padding: 12px">
              <div v-for="rowItem in storeTable" :key="rowItem.rowNo">
                <div
                  v-for="colItem in rowItem.storeTableList"
                  :key="colItem.colNo"
                  :style="{ width: 100 / rowItem.storeTableList.length + '%' }"
                  style="float: left; padding: 6px; box-sizing: border-box"
                >
                  <div
                    :class="[tableId.start ? 'table draggable' : 'table']"
                    :style="{
                      cursor: colItem.useFlag ? 'pointer' : 'not-allowed',
                      backgroundColor: colItem.colorValue
                        ? colItem.colorValue
                        : '#ffffff',
                      opacity: colItem.useFlag ? 1 : 0.5,
                    }"
                    :data-id="colItem.tableId"
                    :data-sort-no="colItem.sortNo"
                    :data-row-no="rowItem.rowNo"
                    :data-column-no="colItem.colNo"
                    @mousedown="onMouseDown"
                    @click="onTableItemClick(colItem)"
                  >
                    <div class="header">
                      {{ colItem.tableName }}
                    </div>
                  </div>
                </div>
                <div style="clear: both; height: 0"></div>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
    <div
      class="table fixed"
      :style="{
        left: `${pos.x + 10}px`,
        top: `${pos.y + 10}px`,
        backgroundColor: getTableInfo().colorValue
          ? getTableInfo().colorValue
          : '#ffffff',
      }"
      v-if="tableId.start"
    >
      <div class="header">
        {{ getTableInfo().tableName }}
      </div>
    </div>
    <store-table-detail-popup
      v-if="isStoreTableDetailPopupOpen"
      ref="storeTableDetailPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      popupKey=""
      @popupEvent="onStoreTableDetailPopupEvent"
      @putStoreTable="putStoreTable"
    />
  </div>
</template>

<style scoped>
.draggable {cursor: move !important;}
.table {height: 134px;box-sizing: border-box;border: 1px solid #ccc;border-radius: 3px;}
.table.fixed {width: 180px;position: fixed;left: 0;top: 0;box-sizing: border-box;background: #ffffff;opacity: 0.5;cursor: move;}
.table > .header {line-height: 21px;background-color: #f9f9f9;border-bottom: 1px solid #e0e0e0;border-radius: 2px 2px 0 0;box-sizing: border-box;padding: 0 8px;}
body .appContent .lookup-condition .field .content .item {width: 90px;}
body .appContent .lookup-condition .field.store .content .item {width: 120px;}
body .appContent .lookup-condition.sub .field .content .item.button {width: 97px;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  getTableUseStore,
  getStoreTable,
  postStoreTable,
  putStoreTable,
  delStoreTableAll,
} from "@/api/storeTableRegistration";
import StoreTableDetailPopup from "../popup/StoreTableDetailPopup";
import InputNumber from "@/components/common/InputNumber";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "storeTableRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    StoreTableDetailPopup,
    InputNumber,
    ErpButton,
  },
  async created() {
    await this.getTableUseStore();
  },
  data() {
    return {
      isStoreTableDetailPopupOpen: false,
      searchOptions: {
        storeCode: null,
        storeCodeOptions: null,
        colNo: 0,
        rowNo: 0,
        fields: { text: "comName", value: "comCode" },
      },
      tableId: {
        start: null,
        end: null,
      },
      tableSortNo: null,
      tableOrgSortNo: null,
      tableRowNo: null,
      tableColumnNo: null,
      pos: {
        x: 0,
        y: 0,
      },
      storeTable: [],
    };
  },
  computed: {
    isPopupOpened() {
      return this.isStoreTableDetailPopupOpen;
    },
  },
  methods: {
    getTableInfo() {
      return this.storeTable
        .find((item) => item.rowNo === this.tableRowNo)
        ?.storeTableList.find((item) => item.colNo === this.tableColumnNo);
    },
    async onStoreTableDetailPopupEvent(eventId) {
      if (eventId === "popupClosed") {
        this.isStoreTableDetailPopupOpen = false; // 추가 팝업 dom 제거
        await this.getStoreTable();
      }
    },
    async onSearchOptionsStoreCodeChange() {
      await this.getStoreTable();
    },
    onMouseDown(event) {
      event.preventDefault();
      document.onmousemove = this.onMouseMove;
      document.onmouseup = this.onMouseUp;
    },
    scrollToTop(el, distance) {
      el.scrollTop += distance;
    },
    onMouseMove(event) {
      event.preventDefault();
      const $el = document.getElementById("table-body");
      const rect = $el.getBoundingClientRect();
      if (event.clientY < rect.top + 100) {
        this.scrollToTop($el, -20);
      } else if (event.clientY > rect.bottom - 100) {
        this.scrollToTop($el, 20);
      }
      const id = event.target.getAttribute("data-id");
      const sortNo = event.target.getAttribute("data-sort-no");
      if (this.tableId.start === null) {
        const rowNo = event.target.getAttribute("data-row-no");
        const columnNo = event.target.getAttribute("data-column-no");
        this.tableId.start = id;
        this.tableOrgSortNo = Number(sortNo);
        this.tableRowNo = Number(rowNo);
        this.tableColumnNo = Number(columnNo);
      }
      this.tableId.end = id;
      this.tableSortNo = Number(sortNo);
      this.pos = {
        x: event.clientX,
        y: event.clientY,
      };
    },
    onMouseUp() {
      if (!!this.tableId?.start && !!this.tableId?.end) {
        if (this.tableId.start !== this.tableId.end) {
          this.putStoreTable(this.getTableInfo());
        }
      }
      this.tableId = {
        start: null,
        end: null,
      };
      this.tableSortNo = null;
      this.tableOrgSortNo = null;
      this.tableRowNo = null;
      this.tableColumnNo = null;
      document.onmouseup = null;
      document.onmousemove = null;
    },
    onTableItemClick(item) {
      const data = JSON.parse(JSON.stringify(item));
      this.isStoreTableDetailPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.storeTableDetailPopup.showPopup(data);
      });
    },
    async onTableCreateButtonClick() {
      await this.postStoreTable();
    },
    async onViewButtonClick() {
      await this.getStoreTable();
    },
    async onInitButtonClick() {
      await this.delStoreTableAll();
    },
    async getStoreTable() {
      const storeCode = this.searchOptions.storeCode;
      if (storeCode === null) {
        return this.errorToast("매장을 선택해 주시기 바랍니다");
      }

      const response = await getStoreTable(storeCode);
      this.storeTable = response.value.storeTable;
    },
    async postStoreTable() {
      if (
        this.searchOptions.rowNo === null ||
        this.searchOptions.rowNo === undefined ||
        this.searchOptions.colNo === null ||
        this.searchOptions.colNo === undefined ||
        !(this.searchOptions.rowNo > 0) ||
        !(this.searchOptions.colNo > 0)
      ) {
        return this.errorToast("가로/세로 갯수를 입력해 주시기 바랍니다");
      }

      if (
        this.searchOptions.storeCode === null ||
        this.searchOptions.storeCode === undefined
      ) {
        return this.errorToast("매장을 선택해 주시기 바랍니다");
      }

      const data = {
        colNo: this.searchOptions.colNo,
        rowNo: this.searchOptions.rowNo,
        storeCode: this.searchOptions.storeCode,
      };

      await postStoreTable(data);
      await this.getStoreTable();
    },
    async putStoreTable(data) {
      if (data.useFlag === null || data.useFlag === undefined) {
        data.useFlag = true;
      }
      if (data.kitchenPrtFlag === null || data.kitchenPrtFlag === undefined) {
        data.kitchenPrtFlag = true;
      }

      const request = {
        storeTableInfo: {
          storeCode: data.storeCode,
          tableId: data.tableId,
          colorValue: data.colorValue,
          tableName: data.tableName,
          useFlag: data.useFlag,
          sortNo: this.tableSortNo || data.sortNo,
          orgSortNo: this.tableOrgSortNo || data.orgSortNo,
          kitchenPrtFlag: data.kitchenPrtFlag,
        },
      };

      // TODO : 서버와의 통신중 임시 예외 처리
      setTimeout(async () => {
        await putStoreTable(request);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.getStoreTable();
      }, 1);
    },
    async delStoreTableAll() {
      const storeCode = this.searchOptions.storeCode;
      if (storeCode === null || storeCode === undefined) {
        return this.errorToast("매장을 선택해 주시기 바랍니다");
      }

      const storeName = this.searchOptions.storeCodeOptions.filter(
        (item) => item.comCode === storeCode
      )[0].comName;

      if (
        !(await this.confirm("[" + storeName + "] 매장을 초기화 하시겠습니까?"))
      ) {
        return;
      }

      await delStoreTableAll(storeCode);
      await this.getStoreTable();
    },
    async getTableUseStore() {
      const response = await getTableUseStore(true);
      this.searchOptions.storeCodeOptions = response.value.comCodeDtlList;
    },
  },
};
</script>
